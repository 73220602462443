// import Vue from 'vue'
import axios from "axios";
import { Component, Watch } from "vue-property-decorator";
import baseUserContextPage from "./baseUserContextPage";
var Qs = require("qs");

@Component({})
export default class chiSiamoPage extends baseUserContextPage {
  showContent: boolean = false;
  get companyProfilePDF() {
    return (process.env.VUE_APP_BASE_URL || '').substring(0, process.env.VUE_APP_BASE_URL.lastIndexOf("/api")) + "/dev/Chi%20siamo/Company-Profile-FULL-2020.pdf";
  }
  onDownloadFile() {
    var instance = this;

    axios.get(`storage/downloadfile?path=dev/Chi%20siamo/Company-Profile-FULL-2020.pdf`,
      { responseType: "blob" },
    ).then((response) => {

      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement('a');

      fileLink.href = fileURL;
      fileLink.setAttribute('download', 'Company-Profile-FULL-2020.pdf');
      document.body.appendChild(fileLink);

      fileLink.click();

      URL.revokeObjectURL(fileLink.href);
      document.body.removeChild(fileLink);
    });
  }

  created() {
    var self = this;

    setTimeout(() => {
      self.showContent = true;

    }, 1);
  }
}
